<template>
  <v-card flat :to="{name:'About'}">
          <!-- src="http://files.elena-jakob.eu/DSC07570.jpg" -->
    <v-img
      src='@/assets/articles/river.jpg'
      class=" lighten-2"
      height="250"
      width="100%"
      position="right">
      <v-row
        class="fill-height pa-3"
        align="center"
      >
        <v-col
          cols="12"
          md="7"
          offset-md="5"
        >
          <h1 class="text-sm-h2 text-h4 font-weight-light white--text" >
            Travel Europe
          </h1>
            <br>
          <div class="subheading text-uppercase pl-2 mb-4 white--text">
              Explore. Climb. Surf. Adventure. 
          </div>

          <div class=" fill-height subheading mb-4 white--text" align='right'>
            <br/> <br/>
            by Elena & Jakob
          </div>

          <!-- SUBSCRIBE BUTTON
          <v-btn
            color="primary"
            depressed
          >
            Subscribe
          </v-btn>
          -->
        </v-col>
      </v-row>
    </v-img>
</v-card>
</template>

<script>

  export default {
    name: 'HomeBanner',
    methods: {
    },

    computed: {
    },
  }
</script>

<style scoped>


</style>